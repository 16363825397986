<template>
	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Gear</h1>
				</div>
			</div>
		</div>
	</div>
    
    <!-- shop section start -->
    <div class="row pt-5" style="place-content: center;">
        <div class="container">
            <p class="h5" v-html="gear.content1"></p>
        </div>
    </div>
    <div class="cy_shop_wrapper pt-4">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="cy_shop_page" id="products">
                        <div class="row">

                            <div class="col-lg-6 col-md-6 item" v-for="(pro, index) in products" :key="index">
                                <div class="cy_store_box">
                                    <div class="cy_store_img">
                                        <router-link :to="'/gear/'+pro.ProductID">
                                            <v-lazy-image :src="pro.images != null ? pro.images : 'http://via.placeholder.com/370x300'"
                                            style="object-fit: cover;height:361px;width:100%" alt="store" class="img-fluid"/>
                                        </router-link>
                                        <div class="cy_store_img_overlay">
                                            <p>Sale</p>
                                        </div>
                                    </div>
                                    <div class="cy_store_data">
                                        <div class="cy_store_text">
                                            <h3><router-link router-link :to="'/gear/'+pro.ProductID">{{ pro.ProductName }}</router-link></h3>
                                        </div>
                                        <div class="mt-3 mb-3" v-html="pro.TeaserText"></div>
                                        <router-link router-link :to="'/gear/'+pro.ProductID" class="cy_button" data-hover="Go to detail"><span>Read More</span></router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home-gear',
    data(){
        return{
            products: [],
            mute:false,
			language_strings : [],
			reslang: [],
			base: 'https://www.admin.tourdeice.bike',
            gear:{
                content1:''
            }
        }
    },
	created(){
		
    },

    mounted() {
        window.scrollTo(0, 0)
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "en")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "en")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

        this.getData()
        this.getDataNode(487, 'gear')
    },

    methods: {
        async getData() {
			window.scrollTo(0, 0)

            this.mute = true
			this.axios.get("product/getclassification/1")
            .then((response) => {
                this.products = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
            })
		},

        getDataNode(id, param) {
            var dataNode = this.$store.dispatch("getNodeById", id)

            dataNode.then(response => {
                this.$data[param] = response.data
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },
    },
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>