<template>
    <div>
        <div class="card card-default">
            <div class="card-body">
                <div class="btn-wrapper">
                    <div id="req" class="tab-pane fade in show">
                        <div class="row" style="margin-bottom:15px;">
                            <div class="col-sm-12">
                                <br>
                                <h3 class="media-heading">{{ reslang.idRequest }}</h3>
                            </div>
                            <div class="col-sm-4">
                                <div :class="bgContact()">
                                    <div class="panel-heading top-bar">
                                        <div class="col-md-8 col-xs-8">
                                            <h3 class="panel-title"><span class="glyphicon glyphicon-book"></span>
                                                Contacts</h3>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <tbody v-if="dataRequest.to_PersonID == $store.getters.currentUser.id">
                                            <tr>
                                                <td>Request From</td>
                                                <td>{{dataRequest.from_PersonName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{{dataRequest.from_Email}}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>{{dataRequest.status}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-center">
                                                    <router-link v-if="$store.getters.currentUser.type == 'B'"
                                                        :to="'/my-dashboard'" class="btn btn-warning btn-sm"><i
                                                            class="fa fa-angle-double-left"></i> Go to Dashboard
                                                    </router-link>
                                                    <router-link v-else :to="'/dashboard'"
                                                        class="btn btn-warning btn-sm"><i
                                                            class="fa fa-angle-double-left"></i> Go to Dashboard
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>

                                        <tbody v-else>
                                            <tr>
                                                <td>Request To</td>
                                                <td>{{dataRequest.to_PersonName}}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{{dataRequest.to_Email}}</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td>{{dataRequest.status}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-center">
                                                    <router-link v-if="$store.getters.currentUser.type == 'B'"
                                                        :to="'/my-dashboard'" class="btn btn-warning btn-sm"><i
                                                            class="fa fa-angle-double-left"></i> Go to Dashboard
                                                    </router-link>
                                                    <router-link v-else :to="'/dashboard'"
                                                        class="btn btn-warning btn-sm"><i
                                                            class="fa fa-angle-double-left"></i> Go to Dashboard
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="chatbody">
                                    <div :class="bgContact()">
                                        <div class="panel-heading top-bar">
                                            <div class="col-md-8 col-xs-8">
                                                <h3 class="panel-title"><span
                                                        class="glyphicon glyphicon-comment"></span> Email Request</h3>
                                            </div>
                                        </div>
                                        <div :class="bgScroll()">

                                            <template v-for="chat in dataChat" :key="chat.id">

                                                <div v-if="chat.sender == $store.getters.currentUser.id"
                                                    class="row msg_container base_sent" :key="chat.id">
                                                    <div class="col-md-12 col-xs-12">
                                                        <div class="messages msg_sent" style="text-align:right;">
                                                            <b>{{dataRequest.to_PersonName}}</b>
                                                            <p style="padding-top:5px;">{{chat.note}}</p>
                                                            <time datetime="2009-11-13T20:00">{{chat.created}}</time>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-else class="row msg_container base_receive">
                                                    <div class="col-md-12 col-xs-12">
                                                        <div class="messages msg_receive">
                                                            <b>{{dataRequest.from_PersonName}}</b>
                                                            <p style="padding-top:5px;">{{chat.note}}</p>
                                                            <time datetime="2009-11-13T20:00">{{chat.created}}</time>
                                                        </div>
                                                    </div>
                                                </div>

                                            </template>

                                        </div>
                                        <div class="panel-footer" style="position: relative;min-height: 50px;">
                                            <button :class="bgButton()"
                                                style="display:none;position: absolute;right: 15px;" id="btn-chat"
                                                data-toggle="modal" data-target="#modalSendRequest">
                                                <i class="fa fa-spinner fa-spin" id="loader_request"
                                                    style="display:none"></i> <i class="fa fa-send fa-1x"
                                                    aria-hidden="true"></i> Reply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="modalSendRequest" tabindex="1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Note</h4>
                    </div>
                    <div class="modal-body">
                        <textarea class="form-control" v-model="reply.note"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" @click="sendRequest()" class="btn btn-success">Send</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
   export default {
        name: 'send-request',
        data(){
            return {
                mute:false,
                dataRequest:{},
                dataChat:{},
                reply:{},
                language_strings : ['idRequest'],
                reslang: [],
            }
        }, 
        created() {

        },
        mounted() {
            this.getdata()
        },
        methods: {
            getdata(){
                if(this.$store.getters.currentUser == undefined){
                    this.$router.push('/')
                }

                var form = {
                    user_id : this.$store.getters.currentUser.id,
                    request_id : this.$route.params.id
                }
                this.mute = true

                if (this.$storage.getStorageSync('langSessionID')) {
					var langid = this.$storage.getStorageSync('langSessionID');
				} else {
					langid = 'en';
				}
				this.language_strings['sess'] = langid;
					var datalangs = this.$store.dispatch('languages', this.language_strings)

                datalangs.then(response => {
                    this.reslang =  response
                })
                .catch( (error) => {
                    console.log(error, 'Got nothing for this user except bad news.')
                })

                this.axios.post('getchat',form)
                .then((response) => {

                    if(response.data.check == 0){
                        this.$router.push('/dashboard')
                    }else{
                        this.dataRequest = response.data.data
                        this.dataChat = response.data.chat
                        
                        document.getElementById("btn-chat").style.display = "block"
                    }
                    this.mute = false

                })
                .catch((error)=>{
                    console.log(error)
                })
            },

            bgContact(){
                if(this.dataRequest.from_PersonID == this.$store.getters.currentUser.id){
                    return "panel panel-success"
                }else{
                    return "panel panel-danger"
                }
            },

            bgButton(){
                if(this.dataRequest.from_PersonID == this.$store.getters.currentUser.id){
                    return "btn btn-success btn-sm"
                }else{
                    return "btn btn-danger btn-sm"
                }
            },

            bgScroll(){
                if(this.dataRequest.from_PersonID == this.$store.getters.currentUser.id){
                    return "panel-body msg_container_base_success"
                }else{
                    return "panel-body msg_container_base_danger"
                }
            },

            sendRequest(){
                if(this.reply.note == null){
                    alert("Please fill note")
                }else{
                    this.reply.sender = this.$store.getters.currentUser.id
                    this.reply.request_id = this.dataRequest.id
                    this.reply.read = 0
                    
                    document.getElementById("btn-chat").disabled = true
                    document.getElementById("myModal").click()
                    document.getElementById("loader_request").style.display = "inline-block"
                    
                    this.axios.post('reply',this.reply)
                    .then((response) => {

                        this.reply.note = response
                        
                        document.getElementById("loader_request").style.display = "none"
                        document.getElementById("btn-chat").disabled = false
                        
                        this.getdata()

                    })
                    .catch((error)=>{
                        console.log(error)
                        alert("Something error, please try again")
                        document.getElementById("loader_request").style.display = "none"
                        document.getElementById("btn-chat").disabled = false
                    })
                }
            },
        },
        watch: {
            mute(val) {
                document.getElementById('mute').style.display = val ? "block" : "none"
            }
        },
    }
</script>

<style scoped>
    .image-box{
        position: relative;
    }
    .bg-imagebox,
    .bg-overlay{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    .bg-imagebox{
        z-index: 1;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        /* background: url(/wp-content/themes/findgo/images/bg.jpg); */
    }
    .bg-overlay{
        z-index: 2;
        background-color: rgba(0, 0, 0, .5);
    }
    .bg-title{
        display: flex;
        padding: 120px 80px 40px;
        align-items: center;
        justify-content: center;    
    }
    .bg-title .head-title{
        margin: 0;
        z-index: 3;
        color: #ffffff;
        font-size: 36px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .breadcrumb{
        margin-bottom: 0;
    }
    .panel-primary>.panel-heading {
        color: #fff;
        background-color: #e7af3e;
        border-color: #e7af3e;
    }

    .panel-primary {
        border-color: #e7af3e;
    }

    .chatperson{
      display: block;
      border-bottom: 1px solid #eee;
      width: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 15px;
      padding: 4px;
    }
    .chatperson:hover{
      text-decoration: none;
      border-bottom: 1px solid orange;
    }
    .namechat {
        display: inline-block;
        vertical-align: middle;
    }
    .chatperson .chatimg img{
      width: 40px;
      height: 40px;
      background-image: url('http://i.imgur.com/JqEuJ6t.png');
    }
    .chatperson .pname{
      font-size: 18px;
      padding-left: 5px;
    }
    .chatperson .lastmsg{
      font-size: 12px;
      padding-left: 5px;
      color: #ccc;
    }

    .col-md-2, .col-md-10{
        padding:0;
    }
    .panel{
        margin-bottom: 0px;
    }
    .chat-window{
        bottom:0;
        position:fixed;
        float:right;
        margin-left:10px;
    }
    .chat-window > div > .panel{
        border-radius: 5px 5px 0 0;
    }
    .icon_minim{
        padding:2px 10px;
    }
    .msg_container_base_success{
      background: #e5e5e5;
      margin: 0;
      padding: 0 10px 10px;
      max-height:300px;
      overflow-x:hidden;
    }

    .msg_container_base_danger{
      background: #e5e5e5;
      margin: 0;
      padding: 0 10px 10px;
      max-height:300px;
      overflow-x:hidden;
    }
    .top-bar {
      background: #666;
      color: white;
      padding: 10px;
      position: relative;
      overflow: hidden;
    }
    .msg_receive{
        padding-left:0;
        margin-left:0;
    }
    .msg_sent{
        padding-bottom:20px !important;
        margin-right:0;
    }
    .messages {
      background: white;
      padding: 10px;
      border-radius: 2px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      max-width:100%;
    }
    .messages > p {
        font-size: 13px;
        margin: 0 0 0.2rem 0;
      }
    .messages > time {
        font-size: 11px;
        color: #ccc;
    }
    .msg_container {
        padding: 10px;
        overflow: hidden;
        display: flex;
    }
    .img-avatar {
        display: block;
        width: 100%;
    }
    .avatar {
        position: relative;
    }
    .base_receive > .avatar:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border: 5px solid #FFF;
        border-left-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0);
    }

    .base_sent {
      justify-content: flex-end;
      align-items: flex-end;
    }
    .base_sent > .avatar:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border: 5px solid white;
        border-right-color: transparent;
        border-top-color: transparent;
        box-shadow: 1px 1px 2px rgba(black, 0.2);
    }

    .msg_sent > time{
        float: right;
    }

    .msg_container_base_success::-webkit-scrollbar-track
    {
        background-color: transparent;
    }

    .msg_container_base_success::-webkit-scrollbar
    {
        width: 12px;
        background-color: #f5f5f5;
    }

    .msg_container_base_success::-webkit-scrollbar-thumb
    {
        background-color: #dff0d8;
        border-radius: 25px;
    }

    .msg_container_base_danger::-webkit-scrollbar-track
    {
        background-color: transparent;
    }

    .msg_container_base_danger::-webkit-scrollbar
    {
        width: 12px;
        background-color: #f5f5f5;
    }

    .msg_container_base_danger::-webkit-scrollbar-thumb
    {
        background-color: #f2dede;
        border-radius: 25px;
    }

    .btn-group.dropup{
        position:fixed;
        left:0px;
        bottom:0;
    }
</style>