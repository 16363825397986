<template>
    <div class="card mt-4 p-4 mb-4">
        <div class="card-body">
            <form @submit.prevent="update" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idFirstName }}:</label>
                            <input type="text" v-model="form.first_name" class="form-control" id="">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idLastName }}:</label>
                            <input type="text" v-model="form.last_name" class="form-control" id="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idEmail }}:</label>
                            <input type="email" v-model="form.email" class="form-control" id="">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idLanguages }}:</label>
                            <select name="select_language" v-model="form.lang_id" class="form-control">
                                <option v-for="(data, index) in optionslang" :key="index" :value="data.value">
                                    {{ data.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="birthDate">{{ reslang.idYearOfBirth }}:</label>
                            <input type="number" v-model="form.birth_date" min="1900" :max="new Date().getFullYear()" step="1" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idGender }}:</label>
                            <select name="select_gender" v-model="form.gender" class="form-control">
                                <option v-for="(data, index) in optionsgender" :key="index" :value="data.value">
                                    {{ data.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idPhoneNumber }}:</label>
                            <input type="text" v-model="form.no_hp" class="form-control" id="">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idAddress }}:</label>
                            <input type="text" v-model="form.address" class="form-control" id="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">{{ reslang.idAbout }}</label>
                            <textarea v-model="form.about" rows="12" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button type="submit" class="btn btn-primary btn-block" id="submit-button" style="cursor:pointer">
                            <i class="fa fa-save"></i> {{ reslang.idSave }}
                        </button>
                    </div>
                    
                    <div class="col-md-6">
                        <router-link :to="`/my-profile`" class="btn btn-danger btn-block">
                            <i class="fa fa-step-backward"></i> {{ reslang.idBack }}
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>

<script>
  export default {
    name: 'editperson-pages',
    data(){
        return {
            language_strings : ['idEdit','idCancel','idPerson','idName','idFirstName','idLastName','idLanguages','idBirthDate',
                            'idGender','idEmail','idPhoneNumber','idTitle','idAddress', 'idSave',
                            'idNotificationEmail','idYearOfBirth','idWeight','idHeight','idBMI','idPublic',
                            'idHandicap','idNickname','idAbout','idAbout','idBack'],
            reslang: [],
            form: {},
            optionslang: [
                { value: 'no', text: 'Norway' },
                { value: 'en', text: 'English' },
                { value: 'id', text: 'Indonesia' }
            ],
            optionsgender: [
                { value: 'M', text: 'Male' },
                { value: 'F', text: 'Female' },
            ],
            mute:false,
        }
    },

    mounted() {
        if (!this.$storage) {
            this.$storage.setStorageSync('langSessionID', "en")
        }

        if(this.$storage.getStorageSync('langSessionID') == undefined){
            this.$storage.setStorageSync('langSessionID', "en")
        }

        this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        })
        .catch((error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })
        
        this.getData();
    },
    methods: {
        getData() {
            this.mute = true
       
            this.axios.get('profile/edit/'+this.$store.getters.currentUser.id)
            .then((response) => {
                this.form = response.data.data.user;
                this.oldimage = this.form.profile_img
                this.person = this.$store.getters.currentUser.id
                this.mute = false
            }).catch((error)=>{
                
                this.$refs.alert.showAlert(
                    'error',
                    error,
                    'Error'
                )

                console.log(error)
                this.mute = false
            });
        },

        update() {
            let submit = document.getElementById('submit-button')

            submit.disabled = true
            this.axios.post('profile/update/'+this.$store.getters.currentUser.id, this.form)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(this.form));
                
                this.$refs.alert.showAlert(
                    'success',
                    response.data.message,
                    'Success'
                )

                submit.disabled = false
                // this.$router.push("/my-profile");
            })
            .catch((error)=>{
                submit.disabled = false

                this.$refs.alert.showAlert(
                    'error',
                    error,
                    'Error'
                )
            });
        },
        
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    },

    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
  }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>