<template>
    <div class="cy_event_wrapper">
        <div class="container">
            <div class="row pt-5">
                <router-link to="/ctc-bike" class="btn btn-info mb-5">Back To TOUR DE ICE</router-link>
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">{{resData.title}}</h1>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="accordion" id="accordionExample">
                            <div v-for="(child, index) in resDataChild" :key="index" class="card mt-3">
                                <div class="card-header" :id="'heading'+index">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    style="text-wrap: balance;"
                                    :data-target="'#collapse'+index" aria-expanded="true" :aria-controls="'#collapse'+index">
                                        {{ child.text }}
                                    </button>
                                </h2>
                                </div>

                                <div :id="'#collapse'+index" class="collapse show" :aria-labelledby="'heading'+index" data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="row m-5">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <div class="content-helpsenter" v-if="child.content1" v-html="child.content1"></div>
                                                <div class="content-helpsenter" v-if="child.content2" v-html="child.content2"></div>
                                                <div class="content-helpsenter" v-if="child.content3" v-html="child.content3"></div>
                                                <div class="content-helpsenter" v-if="child.content4" v-html="child.content4"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div v-if="child.medianode1"><img class="img-thumbnail"
                                                        :src="this.base + child.medianode1" width="280"></div>
                                                <div v-if="child.medianode2"><img class="img-thumbnail"
                                                        :src="this.base + child.medianode2" width="280"></div>
                                                <div v-if="child.medianode3"><img class="img-thumbnail"
                                                        :src="this.base + child.medianode3" width="280"></div>
                                                <div v-if="child.medianode4"><img class="img-thumbnail"
                                                        :src="this.base + child.medianode4" width="280"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="child.video != ''">
                                        <div class="col-md-12">
                                            <video style="width: 100%;height: auto;" controls>
                                                <source :src="this.base + child.video" type="video/mp4">
                                                Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import subContent from './subContentNew.vue';

export default {
	name: 'help-content',
	// components: { subContent},
	data() {
		return {
			resData: {},
			resDataChild: [],
			detailId: 0,
			preLoadContent: 1,
			base: 'https://www.admin.tourdeice.bike',
			loading: true,
		}
	},
	mounted() {
		this.getdata();
	},
	methods: {
		async getdata() {
			
			window.scrollTo(0, 0)

            this.mute = true
			this.axios.get("page/" + this.$route.params.pagetitle + "?page=ctc-bike")
            .then((response) => {
                this.resData = response.data.data
                this.getDataChild(this.resData.id)
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
            })
		},
		async getDataChild(id) {

            this.mute = true
			if (this.$storage.getStorageSync('langSessionID')) {
				var langid = this.$storage.getStorageSync('langSessionID');
			} else {
				langid = 'en';
			}

			this.axios.get("front/childnode/" + id + "?lang=" + langid)
            .then((response) => {
                this.resDataChild = response.data.data.data;
                this.detailId = this.resDataChild[0].value
                this.preLoadContent = 0;
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
            })
		},
	},
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>