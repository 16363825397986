<template>
    <!--Contact section start-->
    <div class="cy_contact_wrapper">
        <div class="container">
            <div class="row pt-5">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">Get In touch</h1>
                </div>
            </div>
            <div class="row padder_top50">
                <div class="col-lg-4 col-md-12">
                    <div class="cy_con_box">
                        <img src="/img/contact/contact1.jpg" alt="phone" class="img-fluid" />
                        <div class="cy_con_overlay">
                            <div class="cy_con_data">
                                <h3>phone</h3>
                                <p><a :href="'tel:'+resData.Phone">{{ resData.Phone }}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="cy_con_box">
                        <img src="/img/contact/contact2.jpg" alt="email" class="img-fluid" />
                        <div class="cy_con_overlay">
                            <div class="cy_con_data">
                                <h3>Email</h3>
                                <p><a :href="'mailto:'+resData.Email">{{ resData.Email }}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="cy_con_box">
                        <img src="/img/contact/contact3.jpg" alt="address" class="img-fluid" />
                        <div class="cy_con_overlay">
                            <div class="cy_con_data">
                                <h3>Address</h3>
                                <p>{{address.Address}} No {{ address.AddressNumber }}, {{address.ZipCode}} {{address.City}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Map section start-->
    <div class="cy_form_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">How we can help you ?</h1>
                    <div class="cy_form padder_top50">
                        <form @submit.prevent="sendEmail" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 padder_left">
                                    <div class="form-group">
                                        <input type="text" v-model="form.name" class="form-control require" :placeholder="reslang.idName" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 padder_right">
                                    <div class="form-group">
                                        <input type="email" v-model="form.email" class="form-control require" :placeholder="reslang.idEmail" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 padder_left padder_right">
                                    <div class="form-group">
                                        <input type="text" v-model="form.title" class="form-control require" :placeholder="reslang.idTitle" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 padder_left padder_right">
                                    <div class="form-group">
                                        <textarea v-model="form.message" class="form-control require" :placeholder="reslang.idMessage" required></textarea>
                                    </div>
                                </div>
                                <div class="response"></div>
                                <div class="col-lg-12 col-md-12 padder_left padder_right">
                                    <button type="submit" class="btn btn-primary" style="background-color: #0099FF;cursor: pointer;" id="submitBtn">{{ reslang.idSubmit }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
    </div>
</template>

<script>
export default {
    name: 'contact-page',
    data(){
        return{
            resData: [],
            address: [],
            mute:false,
			language_strings : ['idName','idEmail','idTitle','idMessage','idSubmit'],
			reslang: [],
            form:{}
        }
    },
	created(){
		
    },

    mounted() {
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "en")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "en")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

        this.dataCompany()
    },

    methods: {
        dataCompany() {
            this.mute = true
            var datares = this.$store.dispatch('getDataCompany', 1);

            datares.then((response) => {
                window.scrollTo(0, 0)
                this.resData = response.data
                this.address = response.address.Visit
                this.mute = false
            });
        },

        sendEmail() {
            let submitBtn = document.getElementById("submitBtn")
            submitBtn.disabled = true

            this.axios.post('profile/contact/', this.form)
            .then((response) => {
                var res = response.data
                this.form = {}
                submitBtn.disabled = false

                this.$refs.alert.showAlert(
                    'success',
                    res.message,
                    'Success'
                )
            })
            .catch((error)=>{
                console.log(error)
                submitBtn.disabled = false
            });

        },
    },
     watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>
