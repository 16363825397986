<template>
	<div class="card mt-4 p-4 mb-4">
		<div class="card-header bg-info text-white">
			<i class="fa fa-question-circle"></i> <span>{{ reslang.idGreenland }}</span>
		</div>
		<div class="card-body">
			<div class="form-group mt-4" v-for="(child, index) in resDataChild" :key="index">
				<div class="alert alert-info alert-collapse" @click="child.id = !child.id" style="cursor:pointer">
					{{ child.text }}
					<span class="close" @click="child.id = !child.id"><i class="fa fa-plus"></i></span>
				</div>
				<div id="subContent" v-if="child.id">
					<div :id="child.id">
						<div class="row">
							<div class="col-md-8">
								<div class="form-group">
									<div v-if="child.content1" class="content-profile" v-html="child.content1"></div>
									<div v-if="child.content2" class="content-profile" v-html="child.content2"></div>
									<div v-if="child.content3" class="content-profile" v-html="child.content3"></div>
									<div v-if="child.content4" class="content-profile" v-html="child.content4"></div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group">
									<div v-if="child.medianode1"><img class="img-thumbnail" :src="this.base+child.medianode1" width="280"></div>
									<div v-if="child.medianode2"><img class="img-thumbnail" :src="this.base+child.medianode2" width="280"></div>
									<div v-if="child.medianode3"><img class="img-thumbnail" :src="this.base+child.medianode3" width="280"></div>
									<div v-if="child.medianode4"><img class="img-thumbnail" :src="this.base +child.medianode4" width="280"></div>
								</div>
							</div>
						</div>
						<div class="row" v-if="child.video != ''">
							<div class="col-md-12">
								<video style="width: 100%;height: auto;" controls>
									<source :src="this.base+child.video" type="video/mp4">
									Your browser does not support HTML5 video.
								</video>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.close {
		opacity: 1;
		font-size: 14px;
		font-weight: 300;
		color: #f7f7f7;
		line-height: inherit;
	}
	.img-thumbnail {
		margin-bottom: 6px;
	}
</style>

<script>
export default {
	name: 'Greenland-component',
	data(){
		return {
			language_strings : ['idGreenland'],
			reslang: [],
			comid:0,
			resData:{},
			resDataChild:[],
			active_el:0,
			detailId:0,
			detailIndex:1,
			loadContent:1,
			preLoadContent:1,
			base: 'https://www.admin.coasttocoast.bike',
			mute: false
		}
	},
	mounted() {
		if (this.$storage.getStorageSync('langSessionID')) {
			var langid = this.$storage.getStorageSync('langSessionID');
		} else {
			langid = 'en';
		}

		this.language_strings['sess'] = langid;
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang =  response
		})
		.catch( (error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})
		
		this.getDataChild(360);
	},
	methods: {
		async getDataChild(id){
			this.mute = true
			if (this.$storage.getStorageSync('langSessionID')) {
				var langid = this.$storage.getStorageSync('langSessionID');
			} else {
				langid = 'en';
			}

			this.axios.get("front/childnode/"+id+"?lang="+langid)
			.then((response) => {
				this.resDataChild = response.data.data.data;
				this.detailId = this.resDataChild[0].value
				this.preLoadContent=0;
				this.mute = false
			})
			.catch((error)=>{
				this.mute = false
				console.log(error)
			})
		},

		activate:function(el){
			this.active_el = el;
			this.preLoadContent=0;
		},

		detailPage(id,index){
			this.detailId = id;
			this.detailIndex = index;
			this.loadContent=1;
			this.preLoadContent=this.loadContent;
		},
	},

	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},

	watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
}
</script>