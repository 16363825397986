<template>
	<!--Banner section start-->
	<div class="cy_bread_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">404</h1>
				</div>
			</div>
		</div>
	</div>
	
    <!--error section start-->
	<div class="cy_error_wrapper cy_section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 col-md-12 offset-lg-2">
					<div class="cy_error_data">
						<h1>404</h1>
						<h2>page you are looking is not found</h2>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
						<a href="/" class="cy_button">Home Page</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>